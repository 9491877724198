import { http } from './http';

export const fetchPaginatedData = async (endpoint, { page = 1, search = "", ...filters }) => {
  const response = await http.get(endpoint, {
    params: {
      page,
      ...(search !== "" && { search }),
      ...filters,
    },
  });
  return response.data;
};
