import { createApp } from 'vue';
import { toast } from 'vue3-toastify';
import App from './App.vue';
import AsyncComputed from 'vue-async-computed'
import {createBootstrap} from 'bootstrap-vue-next'
import Vue3Toastify from 'vue3-toastify';
import * as Sentry from "@sentry/vue";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import { isAPIError, UnauthorizedError } from '@/services/errors';
import { abortAllRequests } from '@/services/http';
import router from '@/router/index.js';
import store from '@/state/store';

import 'vue3-toastify/dist/index.css';
import "@/assets/scss/app.scss";

const app = createApp(App);

// Everything that must be loaded before the app starts
async function preloader() {
  if (__SENTRY_DSN__ !== '') {
    Sentry.init({
      app,
      dsn: __SENTRY_DSN__,
      release: __BUILD_NUMBER__,
      environment: __ENVIRONMENT__,

      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],

      tracesSampleRate: 1.0,
      tracePropagationTargets: ["localhost", __API_URL__],

      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  await store.dispatch('initializeStore');
};

app.config.errorHandler = (err, vm, info) => {
  if (err instanceof UnauthorizedError) {
    abortAllRequests();

    if (store.getters.isLoggedIn) {
      store.dispatch('logout');
      router.push({ name: 'Login' }).then(() => {
        toast.error(err.message);
      });
    }
  } else if (isAPIError(err)) {
    toast.error(err.message);
  } else {
    throw err;
  }
};

preloader().then(
  () => {
    app.use(router);
    app.use(store);
    app.use(createBootstrap());
    app.use(Vue3Toastify, { autoClose: 7000 });
    app.use(AsyncComputed);

    app.mount("#app");
  }
);
