const orderedRoles = [
  'viewer',
  'drafter',
  'doc-editor',
  'editor',
  'manager',
  'admin',
  'superadmin',
]

class User {
  constructor( { id, username, firstName, lastName, email, mustChangePassword, role} ) {
    this.id = id;
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.mustChangePassword = mustChangePassword;
    this.role = role;
  }

  getUserRoleLevel() {
    const userRole = this.role;
    return orderedRoles.indexOf(userRole);
  }

  hasAccess(requiredRole) {
    if (this.getUserRoleLevel() === -1) {
      return false;
    }

    const minRoleLevel = orderedRoles.indexOf(requiredRole);
    if (minRoleLevel === -1 || this.getUserRoleLevel() >= minRoleLevel) {
      return true;
    }
    return false;
  }
}

const AnonymousUser = new User({
  id: '',
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  mustChangePassword: false,
  role: '',
});

export { User, AnonymousUser };
