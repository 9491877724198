import store from '@/state/store';
import api from '@/services/api';

export default [
  {
    path: '/',
    name: 'Index',
    meta: {
      redirectIfAuthenticated: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/account/login.vue'),
    meta: {
      redirectIfAuthenticated: true,
      noAuthRequired: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: { template: '<div></div>' },
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        await api.logout();
        return next({ name: 'Login' });
      },
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/pages/dashboard/index.vue'),
  },
  {
    path: '/envelopes',
    name: 'List envelopes',
    component: () => import('@/views/pages/envelope/list.vue'),
  },
  {
    path: '/envelopes/create',
    name: 'Create new envelope',
    component: () => import('@/views/pages/envelope/create.vue')
  },
  {
    path: '/envelopes/:envelopeId',
    name: 'Modify envelope',
    component: () => import('@/views/pages/envelope/detail.vue'),
    props: true,
  },

  {
    path: '/signatories',
    name: 'List signatories',
    component: () => import('@/views/pages/signatory/list.vue'),
  },
  {
    path: '/signatories/create',
    name: 'Create new signatory',
    component: () => import('@/views/pages/signatory/create.vue'),
  },
  {
    path: '/signatories/:signatoryId',
    name: 'Modify signatory',
    component: () => import('@/views/pages/signatory/detail.vue'),
    props: true,
  },

  {
    path: '/sign/:signingSecret',
    name: 'Sign an envelope',
    meta: {
      noAuthRequired: true,
    },
    component: () => import('@/views/pages/sign/index.vue'),
    props: true,
  },

  {
    path: '/templates/create',
    name: 'Create new template',
    component: () => import('@/views/pages/error/under-construction.vue'),
  },
  {
    path: '/templates/create-envelope',
    name: 'Create envelope from template',
    component: () => import('@/views/pages/error/under-construction.vue'),
  },
  {
    path: '/templates',
    name: 'All templates',
    component: () => import('@/views/pages/error/under-construction.vue'),
  },

  // Audit
  {
    path: '/audit/logs',
    name: 'Activity logs',
    component: () => import('@/views/pages/audit/logs/list.vue'),
  },
  {
    path: '/audit/logs/:logId',
    name: 'View activity log',
    component: () => import('@/views/pages/error/under-construction.vue'),
    props: true,
  },
  {
    path: '/audit/correspondences',
    name: 'Correspondence logs',
    component: () => import('@/views/pages/audit/correspondences/list.vue'),
  },

  // Management
  {
    path: '/management/users',
    name: 'All users',
    component: () => import('@/views/pages/users/list.vue'),
  },
  {
    path: '/management/users/invite',
    name: 'Invite user',
    component: () => import('@/views/pages/users/create.vue'),
  },
  {
    path: '/management/users/:userId',
    name: 'View user',
    component: () => import('@/views/pages/users/detail.vue'),
    props: true,
  },

  {
    path: '/self/change-password',
    name: 'Change password',
    component: () => import('@/views/pages/change-password/index.vue'),
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    meta: {
      noAuthRequired: true,
    },
    component: () => import('@/views/pages/error/http404.vue'),
  },
];
