import { createStore } from 'vuex';
import api from '@/services/api';
import { UnauthorizedError } from '@/services/errors';

import { User, AnonymousUser } from './models';

const store = createStore({
  state: {
    currentUser: AnonymousUser,
    apiToken: null,
  },
  getters: {
    isLoggedIn (state) {
      return state.apiToken !== null
    },
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setAPIToken(state, token) {
      state.apiToken = token;
    },
  },
  actions: {
    async initializeStore({ dispatch, commit }) {
      const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
      await commit('setCurrentUser', new User(currentUser));

      const token = localStorage.getItem('token');
      if (token) {
        await commit('setAPIToken', token);
        dispatch('refreshCurrentUser');
      }
    },
    async refreshCurrentUser({ dispatch, commit }) {
      try {
        const apiCurrentUser = await api.getCurrentUser();
        const currentUser = new User({
          id: apiCurrentUser.id,
          username: apiCurrentUser.username,
          firstName: apiCurrentUser.first_name,
          lastName: apiCurrentUser.last_name,
          email: apiCurrentUser.email,
          mustChangePassword: apiCurrentUser.must_change_password,
          role: apiCurrentUser.role,
        })

        await commit('setCurrentUser', currentUser);
        await localStorage.setItem('currentUser', JSON.stringify(currentUser));
      } catch (error) {
        if (error instanceof UnauthorizedError) {
          await dispatch('logout');
        } else {
          throw error;
        }
      }
    },

    async login({ dispatch, commit }, token) {
      await commit('setAPIToken', token);
      await localStorage.setItem('token', token);
      await dispatch('refreshCurrentUser');
    },
    async logout({ commit }) {
      await commit('setAPIToken', null);
      await commit('setCurrentUser', AnonymousUser);
      await localStorage.removeItem('token');
      await localStorage.removeItem('currentUser');
    },
  },
});

export default store;
