import { createWebHistory, createRouter } from "vue-router";
import * as Sentry from "@sentry/vue";

import store from '@/state/store';
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  sensitive: true,

  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach((routeTo, routeFrom, next) => {
  document.title = routeTo.name ? routeTo.name + ' | ' + __APP_NAME__ : __APP_NAME__;

  if (!store.getters.isLoggedIn) {
    if (routeTo.meta.noAuthRequired) {
      return next();
    } else {
      return next({ name: 'Login' });
    }
  }

  if (routeTo.meta.redirectIfAuthenticated) {
    return next({ name: 'Dashboard' });
  }
  return next();
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    Sentry.captureException(error);
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

export default router
