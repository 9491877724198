class APIError extends Error {
  constructor(error, message) {
    super(message);
    this.name = this.constructor.name;
    this.request = error?.request;
    this.response = error?.response;
    this.httpCode = error?.response?.status;
  }
}

class RequestError extends APIError {}
class ResponseError extends APIError {}

class ClientError extends ResponseError {}
class ServerError extends ResponseError {}

class BadRequestError extends ClientError {}
class UnauthorizedError extends ClientError {}
class ForbiddenError extends ClientError {}
class NotFoundError extends ClientError {}
class TooManyRequestsError extends ClientError {}


export const handleAPIError = (error) =>  {
  if (error.response) {
    const data = error.response.data;
    const message = data.error || data.errors || data.non_field_errors || data.detail || JSON.stringify(data)
    if (error.response.status === 400) {
        throw new BadRequestError(error, message || 'Invalid request.');
    } else if (error.response.status === 401) {
      throw new UnauthorizedError(error, 'Your session has expired. Please log in again.');
    } else if (error.response.status === 403) {
      throw new ForbiddenError(error, data.detail || 'You do not have permission to access this resource.');
    } else if (error.response.status === 404) {
      throw new NotFoundError(error, 'The requested resource could not be found.');
    } else if (error.response.status === 429) {
      throw new TooManyRequestsError(error, message || 'Too many requests. Please try again later.');
    } else if (error.response.status === 500) {
      throw new ServerError(error, 'Oops! Something went wrong. Our engineers have been alerted and are on it.');
    } else if (error.response.status === 503) {
      throw new ServerError(error, 'We are doing maintenance on the server. Please try again soon.');
    } else if (error.response.status === 504) {
      throw new ServerError(error, 'Timeout: The request took too long and was dropped.');
    }
    throw new ClientError(error, `HTTP ${error.response.status} ${message}`);
  } else if (error.request) {
    console.log(error);
    throw new RequestError(error, `Unable to connect to the server. Please check your internet connection.`);
  }
  console.log(error);
  throw new APIError(error, error.message);
};

export const isAPIError = (error) => {
  return error instanceof APIError;
};

export {
  APIError,
  RequestError,
  ResponseError,
  ClientError,
  ServerError,
  BadRequestError,
  UnauthorizedError,
  ForbiddenError,
  NotFoundError,
  TooManyRequestsError,
};
