import axios from 'axios';

import store from '@/state/store';

import { handleAPIError } from './errors';

export const http = axios.create({
  baseURL: __API_URL__,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

let abortController = new AbortController();
export const abortAllRequests = () =>  {
  // Abort all requests and create a new abort controller for future requests
  abortController.abort();
  abortController = new AbortController();
}

http.interceptors.request.use(
  (config) => {
    // Attach an abort signal to each request
    config.signal = abortController.signal;

    // Dynamically add the Authorization header if apiToken is available
    const apiToken = store.state.apiToken;
    if (apiToken && !config.headers.hasOwnProperty('Authorization')) {
      config.headers['Authorization'] = 'Token ' + apiToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (axios.isAxiosError(error)) {
      handleAPIError(error);
    }
    throw error;
  }
);
